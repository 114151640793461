/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import {
  ModalBody,
  Button,
} from 'reactstrap';

import ArticleCollapsable from './ArticleCollapsable';

type Props = {
  deleteAllUserData: ()=>void;
}

const PrivacyPolicyModalBodyEn = ({ deleteAllUserData }: Props) => (
  <ModalBody>
    <ArticleCollapsable className="level-1" title="Preambule">
      <p>At Equipment Maintenance, accessible from https://maintenance.ecogium.fr/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Equipment Maintenance and how we use it.</p>
      <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
      <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Equipment Maintenance. This policy is not applicable to any information collected offline or via channels other than this website.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Data Protection Officer">
      <p>
        For any question related to the privacy policy or any request for the process of the privacy data, please contact by email
        {' '}
        <strong>Paul Torruella paul.torruella@gmail.com</strong>
      </p>
      <p>Explain your demand, mention you name, firstname and the email you used for the subscription if applicable. We will answer you within 10 business days.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Consent">
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Information we collect">
      <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
      <p>When you register for an Account, we ask for your contact information, including items such as name, firstname, and email address.</p>
      <p>For the rest of the personal information you chose to provide are only used for the primary purpose of this website : Assist the user to manage the maintenance of an asset and its equipments.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="How we use your information">
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new services, features, and functionality</li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Log Files">
      <p>
        Equipment Maintenance follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
      </p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Advertising Partners Privacy Policies">
      <p>There is no ad on this website</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Third Party Privacy Policies">
      <p>Equipment Maintenance's Privacy Policy does not apply to other websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
      <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="CCPA Privacy Rights (Do Not Sell My Personal Information)">
      <p>Under the CCPA, among other rights, California consumers have the right to:</p>
      <ul>
        <li>
          Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
        </li>
        <li>
          Request that a business delete any personal data about the consumer that a business has collected by clicking the button below
          <br />
          <Button size="sm" color="danger" onClick={deleteAllUserData}>Delete all the user's data...</Button>
        </li>
        <li>
          Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
        </li>
      </ul>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="GDPR Data Protection Rights">
      <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
      <ul>
        <li>
          The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
        </li>
        <li>
          The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
        </li>
        <li>
          The right to erasure – You have the right to request that we erase your personal data by clicking the below button
          <br />
          <Button size="sm" color="danger" onClick={deleteAllUserData}>Delete all the user's data...</Button>
        </li>
        <li>
          The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
        </li>
        <li>
          The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
        </li>
        <li>
          The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
        </li>
      </ul>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
    </ArticleCollapsable>
    <ArticleCollapsable className="level-1" title="Children's Information">
      <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
      <p>Equipment Maintenance does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
    </ArticleCollapsable>
  </ModalBody>
);

export default PrivacyPolicyModalBodyEn;
